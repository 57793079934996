@-ms-viewport {
    width: device-width;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans Hebrew', sans-serif;
}

#root {
    min-height: 100%;
    overflow-x: hidden;
}

/* @media screen and (max-width: 769px) {
  html { font-size: 50%; }
} */

body {
    overflow-x: hidden;
    font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Coluna Rounded', sans-serif;
}

/* p {
  font-weight: 400;
  font-size: 2rem;
  line-height: 32px;
  letter-spacing: -2%;
} */
